import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '../card'
import Review from '../revieworder'
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import animateScrollTo from 'animated-scroll-to';
import { Redirect } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
const axios = require('axios').default;


const theme = createMuiTheme({
    palette: {
        primary: {
            main:"#ffc0cb"
        },
        secondary: red,
        test: red
    }
});


export default function AddressForm(props) {
    
    // all the states
    const [firstname,
        setFirst] = React.useState('');
    const [lastname,
        setLast] = React.useState('');
    const [address1,
        setAddress1] = React.useState('');
    const [address2,
        setAddress2] = React.useState('');
    const [city,
        setCity] = React.useState('');
    const [zip,
        setZip] = React.useState('');
    const [email,
        setEmail] = React.useState("")
    const [radio,
        setRadio] = React.useState("")
    const [tel,
        setTel] = React.useState("")

    //The form validations states
    const [emailValidate,
        setEmailValidate] = React.useState(false)
    const [emailValidateText,
        setEmailValidateText] = React.useState("")
    const [validationFname,
        setValidationFname] = React.useState(false)
    const [validationTextFname,
        setValidationTextFname] = React.useState("")
    const [validationLname,
        setValidationLname] = React.useState(false)
    const [validationTextLname,
        setValidationTextLname] = React.useState("")
    const [validationAddress,
        setValidationAddress] = React.useState(false)
    const [validationTextAddress,
        setValidationTextAddress] = React.useState("")
    const [validationCity,
        setValidationCity] = React.useState(false)
    const [validationTextCity,
        setValidationTextCity] = React.useState("")
    const [validationZip,
        setValidationZip] = React.useState(false)
    const [validationTextZip,
        setValidationTextZip] = React.useState("")
    const [validationTel,
        setValidationTel] = React.useState(false)
    const [validationTextTel,
        setValidationTextTel] = React.useState("")
    const [helperText, setHelperText] = React.useState('');
    const [error, setError] = React.useState(false);



    //data
    let data = {
        firstname: firstname,
        lastname: lastname,
        address1: address1,
        address2: address2,
        city: city,
        zip: zip,
        email: email,
        tel: tel,
        radio: radio
    }
    useEffect(()=>{
        

    })
    if(props.cart.length === 0){
        alert("Din varukorgen är tomt")
        return <Redirect to="/datorbyggareApp" />
    }
    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = re.test(String(email).toLowerCase());
        if (valid === false){
            setEmailValidate(true)
            setEmailValidateText("Fel Email")
            return
        }else{
            setEmailValidate(false)
            setEmailValidateText("")
    }
    }
    function validatePhone(phone) {
        let nr = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
        let valid = nr.test(String(phone).toLowerCase())
        if(valid === false){
            setValidationTel(true)
            setValidationTextTel("Ange telefon nummer")
        }
    }

    const handelsubmit = () => {

       
        if (validateEmail(email) === false) {
            animateScrollTo(0)
            setEmailValidate(true)
            setEmailValidateText("Fel Email")
            return
        }
        if (validatePhone(tel) === false || tel === "") {
            animateScrollTo(0)
            setValidationTel(true)
            setValidationTextTel("Ange telefon nummer")
            return
        }
        if (firstname === "") {
            animateScrollTo(0)
            setValidationFname(true)
            setValidationTextFname("Ange ett namn")
            return
        }
        if (lastname === "") {
            animateScrollTo(0)
            setValidationLname(true)
            setValidationTextLname("Ange ett efternamn")
            return
        }
        if (address1 === "") {
            animateScrollTo(0)
            setValidationAddress(true)
            setValidationTextAddress("Ange Address")
            return
        }
        if (city === "") {
            animateScrollTo(0)
            setValidationCity(true)
            setValidationTextCity("Ange Ort")
            return
        }
        if (zip === "") {
            animateScrollTo(0)
            setValidationZip(true)
            setValidationTextZip("Ange ett postnummer")
            return
        }
        if(radio === ""){
            animateScrollTo(0)
            setError(true)
            setHelperText("Ange ett leverans sätt")
            return
        }
        props.checkout(data)
    }

        
    return (
        <ThemeProvider theme={theme}>

            <Container maxWidth="sm">
                <Review cartList={props.cart} delete={props.delete} />
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                        Leverans / Installation Address
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                error={validationFname}
                                helperText={validationTextFname}
                                id="firstName"
                                value={firstname}
                                onChange={(e) => {
                                setFirst(e.target.value)
                            }}
                                name="firstName"
                                label="Förnamn"
                                fullWidth
                                autoComplete="name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                error={validationLname}
                                helperText={validationTextLname}
                                id="lastName"
                                value={lastname}
                                onChange={(e) => {
                                setLast(e.target.value)
                            }}
                                name="lastName"
                                label="Efternamn"
                                fullWidth
                                autoComplete="family-name"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                error={validationAddress}
                                helperText={validationTextAddress}
                                id="address1"
                                value={address1}
                                onChange={(e) => {
                                setAddress1(e.target.value)
                            }}
                                name="address1"
                                label="Address line 1"
                                fullWidth
                                autoComplete="billing address-line1"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="address2"
                                value={address2}
                                onChange={(e) => {
                                setAddress2(e.target.value)
                            }}
                                name="address2"
                                label="Address line 2"
                                fullWidth
                                autoComplete="billing address-line2"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                error={validationCity}
                                helperText={validationTextCity}
                                id="city"
                                value={city}
                                onChange={(e) => {
                                setCity(e.target.value)
                            }}
                                name="city"
                                label="Ort"
                                fullWidth
                                autoComplete="billing address-level2"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                error={validationZip}
                                helperText={validationTextZip}
                                id="zip"
                                value={zip}
                                onChange={(e) => {
                                setZip(e.target.value)
                            }}
                                name="zip"
                                label="Post nummer"
                                fullWidth
                                autoComplete="billing postal-code"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                onBlur={()=>{validateEmail(email)}}
                                error={emailValidate}
                                helperText={emailValidateText}
                                fullWidth
                                id="email"
                                value={email}
                                onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                                label="Email Address"
                                name="email"
                                autoComplete="email"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                onBlur={()=>{validatePhone(tel)}}
                                error={validationTel}
                                helperText={validationTextTel}
                                id="tel"
                                value={tel}
                                onChange={(e) => {
                                setTel(e.target.value)
                            }}
                                label="Mobil"
                                name="tel"
                                autoComplete="tel"/>
                        </Grid>
                        <Container maxWidth="sm">
                            <FormControl error={error} component="fieldset">
                        <FormHelperText>{helperText}</FormHelperText>
                                <RadioGroup
                                    style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                                    aria-label="Levrans"
                                    name="Levrans"
                                    value={radio}
                                    onChange={(e) => {
                                    setRadio(e.target.value)
                                }}>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        style={{
                                        padding: "5px"
                                    }}>
                                        <Card
                                            img="https://heshdesk.se/wp-content/uploads/2020/03/Asset-2.png"
                                            title="Omonterad​ Leverans"
                                            kortBesk="Du kan välja att få din dator i delar och montera det själve"
                                            color="inherit"
                                            cardMedia={< FormControlLabel value = "Leverans" control = { < Radio />
                                        }
                                        label = "Leverans" />}/>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        style={{
                                        padding: "5px"
                                    }}>
                                        <Card
                                            img="https://heshdesk.se/wp-content/uploads/2020/03/montering.png"
                                            title="Monterat Leverans"
                                            kortBesk="Du kan välja att få din dator monterat och skickat som paket till dig"
                                            color="inherit"
                                            cardMedia={< FormControlLabel value = "Monterat leverans" control = { < Radio />
                                        }
                                        label = "Montering" />}/>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        style={{
                                        padding: "5px"
                                    }}>
                                        <Card
                                            img="https://heshdesk.se/wp-content/uploads/2020/03/Asset-3.png"
                                            title="HeshDesk Instalation"
                                            kortBesk="Fysisk installation på plats med utökad HeshDesk stöd"
                                            color="inherit"
                                            cardMedia={< FormControlLabel disabled value = "HeshDesk Instalation" control = { < Radio />
                                        }
                                        label = "HeshDesk Instalation" />}/>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        style={{
                                        padding: "5px"
                                    }}>
                                        <Card
                                            img="https://heshdesk.se/wp-content/uploads/2020/03/Asset-3.png"
                                            title="HeshDesk Instalation (Utökad)"
                                            kortBesk="Fysisk installation på plats med utökad HeshDesk stöd samt utbildning om hur dator montering och dator kompnenter"
                                            color="inherit"
                                            cardMedia={< FormControlLabel disabled value = "HeshDesk Instalation (Utökad)" control = { < Radio />
                                        }
                                        label = "HeshDesk Instalation (Utökad)" />}/>
                                    </Grid>
                                </RadioGroup>
                                
                            </FormControl>
                        </Container>
                        <Grid item xs={12} sm ={12}>
                            <Alert variant="filled" severity="warning">
                                HeshDesk Instalation är inte tillgänglig pga COVID-19 Stay Safe, Stay Home
                            </Alert>
                        </Grid>
                        
                        <Grid item xs={12} sm ={12}>
                        <Typography variant="h3" gutterBottom>
                                {props.total.reduce((a,b)=>a+b, 0)} kr
                        </Typography>
                        </Grid>
                        <Button
                            type="submit"
                            onClick={handelsubmit}
                            fullWidth
                            variant="contained"
                            color="primary">Klarna Checkout</Button>
                            <Grid item xs={12} sm={12}>
                        <img src="https://cdn.klarna.com/1.0/shared/image/generic/badge/sv_se/checkout/long-blue.png?width=440" style={{maxWidth:"100%"}} alt="Köp nu Betala sen Klarna" />
                    </Grid>
                    </Grid>
                    
                </React.Fragment>
            </Container>
            <div id="klarna-payments-container"></div>
        </ThemeProvider>
    );
}
