import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from './card'
import AddShoppingCartSharpIcon from '@material-ui/icons/AddShoppingCartSharp';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    grid: {
        maxWidth: "100%",
    },
    girdContainer: {
        flexGrow: 1,
        padding: 0
    },
    margin: {
        margin: theme.spacing(1),
        backgroundColor:"#4caf50",
        color:"#e1f5fe"
      },
      card: {
          backgroundColor:"#4caf50"
      }
}));

export default function AutoGrid(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid
                className={classes.girdContainer}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}>
                {props.list.map(x=>
                <Grid key={x.id} className={classes.grid} item xs={12} sm={12} md={4} lg={3}>
                    <Card 
                    className={classes.card}
                    link="#"
                    cardMedia={
                        <Fab
          onClick={props.click}
          variant="extended"
          size="medium"
          color={props.color}
          aria-label="add"
          className={classes.margin}
        >
            {x.regular_price+"  sek   "}
          {"  "}
        </Fab>
                        
                    }
                    ic={<AddShoppingCartSharpIcon />}
                    title={x.name}
                    img={x.images[0].src}
                    kortBesk={<span dangerouslySetInnerHTML={{__html:x.short_description}} />}
                    cartFab={<Fab
                        onClick={()=>props.addCart(x,x.categories[0].slug)}
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        className={classes.margin}
                      >
                          {<AddShoppingCartSharpIcon />}
                      </Fab>}
                    />
                </Grid>
                )}
            </Grid>
        </div>
    );
}
