import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function DirectionSnackbar(props) {
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(undefined);

  const handleClick = Transition => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    setOpen(true)
  },[props.txt])

  return (
    <div>
      <Snackbar
        autoHideDuration={10000}
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
      >
        <Alert onClose={handleClose} severity={props.sev}>
          {props.txt}
        </Alert>
      </Snackbar>
    </div>
  );
}
