import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Granska din beställning</Typography>
          <Typography className={classes.secondaryHeading}>Varukorg lista</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <List>
              {props.cartList.map((x,key)=>
              <div key={x.id+key}>
              <ListItem  >
                {console.log(x)}
                <ListItemIcon ><img alt={x.name} style={{width:"140px", marginRight:"10px" , maxHeight:"140px"}} src={x.images[0].src} /></ListItemIcon>
                <Typography variant="button" display="block" gutterBottom>
                    {x.price}{" kr"}
                </Typography>
              <DeleteForeverIcon color="error" onClick={()=>{props.delete(x)}} />
              </ListItem>
              <Divider/>
              </div>
              )}
            </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
