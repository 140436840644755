import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Card from './card'
import Container from '@material-ui/core/Container';
const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

let statusHash = queryString.parse(window.location.hash)
let status = statusHash.status

export default function SimpleAlerts(props) {
  const classes = useStyles();
  const [titel,setTitel] = React.useState('')
  const [beskriv,setBeskriv] = React.useState('')
  const [media,setMedia] = React.useState('')
  const [alerttxt,setAlerttxt] = React.useState('')
  
  useEffect(() => {
    switch(status){
      case 'success':
        setTitel("Komponenterna börjar samlas ihop snart")
        setBeskriv("Vi kommer förbereda din beställning så fort så möjligt, du kommer få meddelande när din order skickas.")
        setAlerttxt('Din betalning är mottagen.')
        break;
      case 'cancel':
      case 'back':
      case 'error':
        setTitel("Något gick fel")
        setBeskriv("Om du får den här fel ofta, vänligen kontakta oss")
        setAlerttxt('kontakta oss om du behöver hjälp med beställningen')
        break;
      default:
        setTitel("Inget beställning")
        setBeskriv("Vi har inte fått in bestälningen, Har du bestält men få den här meddelande ändå kontakta oss")
        setAlerttxt("Kontakta oss på info@heshdesk.se")

    }

  },[titel,beskriv])
  return (
    <Container maxWidth="sm">
    <div className={classes.root}>
      <Card
        img="https://heshdesk.se/wp-content/uploads/2020/03/Asset-1-1.png"
        title={titel}
        kortBesk={beskriv}
        cardMedia={media}
       />
     <Alert variant="filled" severity={props.severity}>
        {alerttxt}
      </Alert> 
    </div>
    </Container>
  );
}