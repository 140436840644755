import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Cart from './cart'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(4)
    },
    bar: {
        color: "#e91e63",
        background: "#fbf7f5",
    },
    titel:{
        flexGrow: 1
    },
    cart: {
    }
}));
export default function ButtonAppBar(props) {
    const classes = useStyles();
    function toggle (x) { 
        x('left', true)
    }
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.bar}>
                <Toolbar>
                    <a href="https://heshdesk.se/" className={classes.menuButton}>
                    <img src="https://heshdesk.se/wp-content/uploads/2019/10/heshdesk.png" alt="heshdesk Logo" style={{width:"55px",paddingTop:"5px"}}/>
                    </a>
                    <Typography variant="h6" className={classes.titel} >
                        Datorbyggare
                    </Typography>
                    <Cart total={props.total} delete={props.delete} badgeNr={props.badgeNr} cartList={props.cartList} checkout={props.checkout} toggle={toggle} className={classes.cart} />
                </Toolbar>
            </AppBar>
        </div>
    );
}
