import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import {Link } from "react-router-dom";



const StyledBadge = withStyles(theme => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}))(Badge);

const useStyles = makeStyles(theme => ({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto'
    },
    margin: {
        margin: theme.spacing(1),
      },
      link:{
        textDecoration: "solid"
      },
}));

export default function TemporaryDrawer(props) {
    const classes = useStyles();
    const [state,
        setState] = React.useState({top: false, left: false, bottom: false, right: false});
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({
            ...state,
            [side]: open
        });
    };
/*     const test = JSON.parse(localStorage.getItem("cart"))
 */    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}>
            <List>
              {props.cartList.map((x,key)=>
              <div key={x.id+key}>
              <ListItem  >
                <ListItemIcon ><img alt={x.name} style={{width:"140px", marginRight:"10px" , maxHeight:"140px"}} src={x.images[0].src} /></ListItemIcon>
                <Typography variant="button" display="block" gutterBottom>
                    {x.price}{" kr"}
                </Typography>
              <DeleteForeverIcon color="error" onClick={()=>{props.delete(x)}} />
              </ListItem>
              <Divider/>
              </div>
              )}
            </List>
            <Link to="/datorbyggareApp/checkout" className={classes.link}>
            <Fab
          onClick={props.checkout}
          variant="extended"
          size="medium"
          color="secondary"
          aria-label="add"
          className={classes.margin}
              >{props.total.reduce((a,b)=>a+b, 0)}{"  kr"}<ShoppingCartIcon style={{marginLeft:"7px"}} /></Fab></Link>
        </div>
    );

    return (
        <div>
            <IconButton
                onClick={() => {
                props.toggle(toggleDrawer('right', true))
            }}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu">
                <StyledBadge badgeContent={props.badgeNr} color="secondary">
                    <ShoppingCartIcon/>
                </StyledBadge>
            </IconButton>
            <Drawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>
        </div>
    );
}
