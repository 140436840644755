import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Link } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import Card from './card'
import BuildIcon from '@material-ui/icons/Build';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    grid: {
        maxWidth: "400px"
    },
    girdContainer: {
        flexGrow: 1,
        padding: 40
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    link:{
      textDecoration: "solid"
    },
  }));

  export default function AutoGrid(props) {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Grid
                    className={classes.girdContainer}
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={4}>
                    <Grid className={classes.grid} item sm={4}>
                        <Card
                            kortBesk="Du använder din dator för att få saker gjorda. Det blir mest Office, surf, mail och räkningar."
                            cardMedia={
                              <Link to="/datorbyggareApp/standerd" className={classes.link}>
                              <Fab
                                  onClick={()=>props.click("/datorbyggareApp/standerd")}
                                  variant="extended"
                                  size="medium"
                                  color="secondary"
                                  aria-label="add"
                                  className={classes.margin}
                                >
                                  <BuildIcon className={classes.extendedIcon} />
                                  {"  "}
                                </Fab>
                                </Link>}
                            title="Standerd Setup"
                            img={"https://heshdesk.se/wp-content/uploads/2019/11/Standard-home-setup-.png"}/>
                            
                    </Grid>
                    <Grid className={classes.grid} item sm={4}>
                        <Card
                            kortBesk="Du researchar, mailar och kör Skype med vänner. Du jobbar kanske som frilansare eller bloggare och gillar att få saker gjorda och publicerade direkt"
                            title="Professionell Setup"
                            img={"https://heshdesk.se/wp-content/uploads/2019/11/heshdesk-pro-setup.png"}
                            cardMedia={
                            <Link to="/datorbyggareApp/pro" className={classes.link}>
                            <Fab
                                onClick={()=>props.click("/datorbyggareApp/pro")}
                                variant="extended"
                                size="medium"
                                color="secondary"
                                aria-label="add"
                                className={classes.margin}
                              >
                                <BuildIcon className={classes.extendedIcon} />
                                {"  "}
                              </Fab>
                              </Link>}
                            />
                            
                    </Grid>
                    <Grid className={classes.grid} item sm={4}>
                        <Card
                            kortBesk="Du som spelar vill ha rejäl prestanda, det vill säga mycket RAM-minne, kraftfull grafik, snabb ssd-disk och en processor av senaste generationen."
                            title="Gaming Setup"
                            cardMedia={
                              <Link to="/datorbyggareApp/gaming" className={classes.link}>
                              <Fab
                                  onClick={()=>props.click("/datorbyggareApp/gaming")}
                                  variant="extended"
                                  size="medium"
                                  color="secondary"
                                  aria-label="add"
                                  className={classes.margin}
                                >
                                  <BuildIcon className={classes.extendedIcon} />
                                  {"  "}
                                </Fab>
                                </Link>}
                            img={"https://heshdesk.se/wp-content/uploads/2019/11/HeshDesk-gaming-setup.png"}/>
                    </Grid>
                    <Grid className={classes.grid} item sm={12}>
                      <Card
                          kortBesk="Vi har satt ihop prisvärda datorpaket som är anpassat för de olika behov. Välj den som passar dina behov och låt oss hjälpa dig."
                          mainFab={<KeyboardArrowRightIcon />}
                          link=""
                          color="secondary"
                          title="Färdigbyggd dator paket"
                          img={"https://heshdesk.se/wp-content/uploads/2020/03/Asset-1-1.png"}
                      />
                    </Grid>
                </Grid>
      </div>
    );
  }

                    