import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from './tabs'
import Hitta from './fundfunc'
import Cpu from './cpu'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    grid: {
        maxWidth: "100%"
    },
    girdContainer: {
        flexGrow: 1,
        padding: 40
    }
}));

export default function AutoGrid(props) {
    const classes = useStyles();
    let productFilter = props.lista
    let cpuList = Hitta(productFilter, "cpu")
    let motherList = Hitta(productFilter,"motherbord")
    let ramList = Hitta(productFilter,"ram")
    let chassiList = Hitta(productFilter,"chassi")
    let psuList = Hitta(productFilter,"psu")
    let kylningList = Hitta(productFilter,'kylning')
    let gpuList = Hitta(productFilter,'gpu')
    let ssdList = Hitta(productFilter,'ssd')

    return (
        <div className={classes.root}>
            <Grid
                className={classes.girdContainer}
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}>
                     <Tabs
                     //here is a list of all the componenet @To add tabs go to tabs@"the data will always sen from above from the app.js"
                        cpu={<Cpu addCart={props.callback} delete={props.delete} list={cpuList} />}
                        moderkort={<Cpu addCart={props.callback} delete={props.delete} list={motherList} />}
                        ram={<Cpu addCart={props.callback} delete={props.delete} list={ramList} />}
                        chassi={<Cpu addCart={props.callback} delete={props.delete} list={chassiList} />}
                        psu={<Cpu addCart={props.callback} delete={props.delete} list={psuList} />}
                        kylning={<Cpu addCart={props.callback} delete={props.delete} list={kylningList} />}
                        gpu={<Cpu addCart={props.callback} delete={props.delete} list={gpuList} />}
                        ssd={<Cpu addCart={props.callback} delete={props.delete} list={ssdList} />}
                    />
            </Grid>
        </div>
    );
}
