import React, {Component} from 'react';
import AppBar from './bar'
import './App.css';
import {BrowserRouter as Router, Route} from "react-router-dom";

import FirstStep from './firststep'
import Steps from './steper'
import Standerd from './standerd'
import Divider from '@material-ui/core/Divider';
import Hitta from './fundfunc'
import Load from './loading'
import Confirm from './confirm'
import Snack from './snackbar'
import Checkout from './checkout/Checkout';
import { Button } from '@material-ui/core';
const axios = require('axios').default;
const queryString = require('query-string');


const reducer = (accumulator, currentValue) => accumulator + currentValue;


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepNr: 0,
            cpulist:[],
            cart:[],
            standerd:[],
            pro:[],
            gaming:[],
            load:true,
            totalArr:[],
            total:this.totalArr,
            cartCheck:false,
            confirm:false,
            redirect:"#",
            severity:"info",
            heshdeskID:"",
            link:"/datorbyggareApp",
            addsnack:""
        }
        this.callback = this.callback.bind(this)
        this.delete = this.delete.bind(this)
        this.createOrder = this.createOrder.bind(this)
        this.setlink = this.setlink.bind(this)
        }
        
    componentDidMount() {
        let status = queryString.parse(window.location.hash)
        if(status.status !== undefined){
            this.setState({severity:status.status})
        }
        
        let id = queryString.parse(window.location.search)
        console.log(id.id)
        axios
            .get("https://heshdesk-175109.appspot.com/api/list_product")
            .then((x) => {
                let data = x.data
                let standerd = Hitta(data,"standerd")
                let pro = Hitta(data,"pro")
                let gaming = Hitta(data,"gaming")
                this.setState({standerd:standerd,pro:pro,gaming:gaming,load:false})
            })
            axios.get("https://heshdesk-175109.appspot.com/api/orders")
            .then((x)=>{
            })
    }

   
    callback = (x,y)=>{
        let cat = x.categories[0].slug
        let checkCart = Hitta(this.state.cart,cat)
            switch(cat){
                case 'cpu':
                case 'motherbord':
                case 'chassi':
                case 'psu':
                    if(checkCart.length > 0){
                        alert("Du kan inte lägga mer än en " + cat)//To be added a popup alert
                    }else{this.setState({cart:[...this.state.cart,x],totalArr:[...this.state.totalArr,parseInt(x.price)],addsnack:<Snack sev='success' txt={x.name+" läggs till i varukorgen"} />})
                }
                    break;
                case 'ram':
                        if(checkCart.length > 2){
                            alert("Är du säker att du behöver så här mycket " + cat)//To be added a popup alert
                        }else{this.setState({cart:[...this.state.cart,x],totalArr:[...this.state.totalArr,parseInt(x.price)],addsnack:<Snack sev='success' txt={x.name+" läggs till i varukorgen"} />})
                        }
                        break;
                default:
                    this.setState({cart:[...this.state.cart,x],totalArr:[...this.state.totalArr,parseInt(x.price)],addsnack:<Snack sev='success' txt={x.name+" läggs till i varukorgen"} />})

            }
    }

    createOrder = (data)=>{
        let productMap = this.state.cart.map((x)=>{
            return {
                "type": "physical",
                "reference": x.id,
                "name": x.name,
                "quantity": 1,
                "quantity_unit": "styck",
                "unit_price": x.price * 100,
                "tax_rate": 2500,
                "total_amount": x.price * 100,
                "total_tax_amount": x.price * 2000/100,
                "image_url": x.images[0].src
            }
        })
        let HeshDeskProductMap = this.state.cart.map((x)=>{
            return{
                product_id:x.id,
                quantity:1
            }
        })
        let total = this.state.totalArr.reduce(reducer) * 100
        let totalTax = total * 20/100
        let klarnaData = {
        "purchase_country": "se",
        "purchase_currency": "sek",
        "locale": "sv-se",
        "billing_address": {
            "given_name": data.firstname,
            "family_name": data.lastname,
            "email": data.email,
            "street_address": data.address1,
            "postal_code": data.zip,
            "city": data.city,
            "phone": data.tel,
            "country": "se"
          },
        "order_amount": total ,
        "order_tax_amount": totalTax,
        "order_lines": productMap,
        "merchant_urls": {
            "terms": "https://heshdesk.se/forsaljningsvillkor/",
            "checkout": "http://merchant.com/datorbyggareApp/checkout.php?sid={checkout.order.id}",
            "confirmation": "http://merchant.com/thankyou.php?sid={checkout.order.id}",
            "push": "http://localhost/kco/push.php?sid={checkout.order.id}"
        },
        "shipping_options": [
            {
              "id": "Omonterad​_Leverans",
              "name": "Omonterad​ Leverans",
              "description": "Delivers in 3-7 days",
              "price": 19900,
              "tax_amount": 0,
              "tax_rate": 0,
              "preselected": true,
              "shipping_method": "Post Nord Paket"
            },
            {
              "id": "Monterad_Leverans",
              "name": "Monterad Leverans",
              "description": "Delivers in 5-7 days",
              "price": 100000,
              "tax_amount": 0,
              "tax_rate": 0,
              "preselected": false,
              "shipping_method": "PickUpStore"
            },
            {
                "id": "HeshDesk_Instalation",
                "name": "HeshDesk Instalation",
                "description": "Betala resten vid Instalation (HeshDesk kommer kontakta dig)",
                "price": 100000,
                "tax_amount": 0,
                "tax_rate": 0,
                "preselected": false,
                "shipping_method": "Betala resten vid Instalation"
            }
          ]
        }
        let heshDeskorderData={
            payment_method: data.radio,
            payment_method_title: data.radio,
            set_paid: false,
            billing:{
                first_name: data.firstname,
                last_name: data.lastname,
                address_1: data.address1,
                address_2: data.address2,
                city: data.city,
                postcode: data.zip,
                email: data.email,
                phone: data.tel
            },
            shipping:{
                first_name: data.firstname,
                last_name: data.lastname,
                address_1: data.address1,
                address_2: data.address2,
                city: data.city,
                postcode: data.zip,
            },
            line_items:HeshDeskProductMap,
        }
        axios.post("https://heshdesk-175109.appspot.com/api/klarna/createorder",klarnaData)
        .then((x)=>{
            let id = x.data.data.order_id
            let hppdata = {
                "payment_session_url": `https://api.playground.klarna.com/checkout/v3/orders/${id}`,
                "merchant_urls": {
                    "success": `https://heshdesk.se/datorbyggareApp/bekrafta#status=success&?sid=${id}`,
                    "cancel": `https://heshdesk.se/datorbyggareApp/bekrafta#status=info&?sid=${id}`,
                    "back": `https://heshdesk.se/datorbyggareApp/bekrafta#status=back&?sid=${id}`,
                    "failure": `https://heshdesk.se/datorbyggareApp/bekrafta#status=error&?sid=${id}`,
                    "error": `https://heshdesk.se/datorbyggareApp/bekrafta#status=error&?sid=${id}`
                }
            }
            axios.post("https://heshdesk-175109.appspot.com/api/klarna/hpp",hppdata)
            .then((x)=>{
                axios.post("https://heshdesk-175109.appspot.com/api/orders",heshDeskorderData)
                .then((res)=>{
                    this.setState({heshdeskID:res.data.id})
                })

                this.setState({redirect:x.data.data.redirect_url})
            })
        })
    }

    setlink = (link)=>{
        this.setState({link:link})
    }

    delete = (product)=>{
        let arr = this.state.cart
        let tArr = this.state.totalArr
        let found = arr.find(x=> x.id === product.id)
        let index = arr.indexOf(found)
        arr.splice(index,1)
        this.setState({cart:arr})
        let str = product.price
        let nr = JSON.parse(str)
        let pIndex = tArr.indexOf(nr)
        tArr.splice(pIndex,1)
        this.setState({totalArr:tArr})
    }


    render() {
        if(this.state.load){
            return <Load />
        }
        if(this.state.redirect !== "#"){
            window.location.href = this.state.redirect
            return {/* <Router><Redirect to={this.state.redirect} /></Router> */}
        }

        return (
            <Router>
                <div className="App">
                    <AppBar  delete={this.delete} total={this.state.totalArr} cartList={this.state.cart} badgeNr={this.state.cart.length} />
                    <Route
                        path="/datorbyggareApp"
                        exact
                        render={(props) => <> <Steps link={this.state.link} con={0}/> 
                        < FirstStep 
                        click={this.setlink}
                        {
                        ...props
                    } />
                    <Snack sev="info" txt="Leverans eller montering väljer du under checkout!" />
                     </>}/>
                    <Route path="/datorbyggareApp/standerd" exact render={(props) => 
                    <>
                    <Steps link={this.state.link} con={1} />
                    <Divider variant="middle" />
                    <Standerd   lista={this.state.standerd}
                                callback={this.callback}
                                delete={this.delete}
                                />
                    <Snack sev="info" txt="Leverans eller montering väljer du under checkout!" />
                    {this.state.addsnack}
                    </>
                    }
                    />
                    <Route
                        path="/datorbyggareApp/pro" exact render={(props) =>
                            <>
                            <Steps link={this.state.link} con={1} />
                            <Divider variant="middle" />
                            <Standerd   lista={this.state.pro}
                                callback={this.callback}
                                delete={this.delete}
                                />
                            <Snack sev="info" txt="Leverans eller montering väljer du under checkout!" />
                            {this.state.addsnack}
                            </>
                        }
                     />
                     <Route
                        path="/datorbyggareApp/gaming" exact render={(props) =>
                            <>
                            <Steps link={this.state.link} con={1} />
                            <Divider variant="middle" />
                            <Standerd   lista={this.state.gaming}
                                callback={this.callback}
                                delete={this.delete}
                                />
                            <Snack sev="info" txt="Leverans eller montering väljer du under checkout!" />
                            {this.state.addsnack}
                            </>
                        }
                     />
                    <Route path="/datorbyggareApp/checkout" exact render={(props) => 
                    <>
                    <Steps link={this.state.link} con={2} />
                    <Checkout 
                        delete={this.delete}
                        total={this.state.totalArr}
                        cart={this.state.cart}
                        checkout={this.createOrder}
                     />
                     </>} />
                     <Route path="/datorbyggareApp/bekrafta" exact render={(props) => 
                    <>
                    <Steps link={this.state.link} con={3} />
                    <Confirm 
                        id={this.state.heshdeskID}
                        severity={this.state.severity}
                        checkout={this.createOrder}
                     />
                     </>} />
                </div>
            </Router>
        );
    }
}


export default App;
